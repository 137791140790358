import React from "react"
import FavouriteStyles from "./favourite.module.css"
import {RecipeContext} from "../../../recipecontext"

export default function Favourite(props){
    const context = React.useContext(RecipeContext);
    const [isFavourite,setIsFavourite] = React.useState(props.isFave);

    const toggleFave = (e) => {
        console.log(isFavourite ? "Removing from favourites" : "Adding to favourites");
        if(isFavourite){
            let index = context.favourites.indexOf(props.recipeId);
            context.removeFavourite(index);
            setIsFavourite(!isFavourite);
        } else {
            if(context.addFavourite(props.recipeId)) {setIsFavourite(!isFavourite)}
        }
    }

    var favIcon = isFavourite ? "favorite" : "favorite_border";
    var iconColor = { color: isFavourite ?  "white" : "grey"};
    return (
        <i role="button" tabIndex={0} onKeyDown={toggleFave} style={iconColor} onClick={toggleFave} className={`${FavouriteStyles.favourite} material-icons`}>{ favIcon }</i>
    )
}
