import React from "react"
import CardStyles from "./card.module.css"
import Favourite from "./Favourite"
import {RecipeContext} from "../../recipecontext"

export default function Card(props) {
    const context = React.useContext(RecipeContext);
    const defaultImage= "hero_boule.png";

    let image = props.recipe.image ? props.recipe.image : defaultImage;

    let isFave;
    if (context.favourites == null) {
        isFave = false;
    } else {
        isFave = (context.favourites.indexOf(props.recipe.recipeId) >= 0) ? true : false;
    }

    let showFavourite = (context.allowFaves === 'false') ? `` : <Favourite recipeId={props.recipe.recipeId} isFave={isFave} />;

    React.useEffect(()=>{
        const status = localStorage.getItem('allowFaves');
        context.handleChange("allowFaves",status);
    },[])

    return (
        <div className={CardStyles.card__container}>
            {showFavourite}
            <div aria-label="Select recipe" role="button" tabIndex={0} onKeyDown={(e)=>{context.handleChange("recipe",props.recipe);
}} onClick={(e)=>{context.handleChange("recipe",props.recipe);
}} className={CardStyles.card__content}>
                <img alt={props.recipe.name} className={CardStyles.card__image} src={require(`../../images/${image}`)} />
                <div className={CardStyles.card__title}>{ props.recipe.name }</div>
                <div className={CardStyles.card__description}>{ props.recipe.description }</div>
            </div>
        </div>
    )
}
