import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import JSONData from "../data/recipes.json"

import Header from "../components/Header"
import CardList from "../components/CardList"
import Card from "../components/Card"
import Picker from "../components/Picker"

import {RecipeContext} from "../recipecontext"

export default function RecipeList() {
    const context = React.useContext(RecipeContext);

    // GraphQL query hook
    const data = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                    }
                }
            }
        `
    )


    let cards = []
    let nonFaves = [];
    if(context.favourites == null) {
        cards = JSONData.map((data,index) => {
            return <Card key={index} recipe={data} />
        });
    } else {
        cards = JSONData.map((data,index) => {
            if(context.favourites.indexOf(data.recipeId) >= 0) {
                return <Card key={index} recipe={data} />
            } else {
                nonFaves.push(<Card key={index} recipe={data} />)
                return
            }
        });
        cards.push(nonFaves);
    }

  return (
    <div>
        <Header>{data.site.siteMetadata.title}</Header>
        <CardList>
            {(context.recipe===null) ? `` : <Picker /> }
            {cards}
        </CardList>
    </div>
    )
}
