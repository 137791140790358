import React from "react"
import CardListStyles from "./cardlist.module.css"

export default function CardList(props) {
  return (
      <div className={CardListStyles.cardlist}>
      {props.children}
      </div>
  )
}
