import React from "react"
import PickerStyles from "./picker.module.css"
import {navigate} from '@reach/router';
import {RecipeContext} from "../../recipecontext"

export default function Picker(){
    const [error,setError] = React.useState("");

    const handleClick = (e) => {
        // Need to add some input validation and error handling
        const minWeight = context.units=== "g" ? 100 : 4;
        if ( context.doughWeight >= minWeight ){
            navigate(`/${context.recipe.name}/${context.doughWeight}`);
        } else {
            setError(`Please enter a value more than ${minWeight}${context.units}`);
        }
    }
    const clearModal = () => {
        context.handleChange("recipe",null);
    }
    const context = React.useContext(RecipeContext);
    /* const options = (
        <div className={PickerStyles.picker__input}>
            <label className={PickerStyles.picker__label} htmlFor="preferment">Preferment:
                <input type="checkbox" id="preferment" name="preferment" onBlur={(e)=> context.handleChange(e.target.name,e.target.checked)} />
            </label>
            <label className={PickerStyles.picker__label} htmlFor="autolysis">Autolysis:
                <input type="checkbox" id="autolysis" name="autolysis" onBlur={(e)=> context.handleChange(e.target.name,e.target.checked)} />
            </label>
        </div>
    ) */
    return (
        <div>
            <div className={PickerStyles.modal}>
                <i aria-label="Close Modal" role="button" className={`${PickerStyles.closeButton} material-icons`} onClick={clearModal} onKeyDown={clearModal} tabIndex={0}>cancel</i>
                <h2 className={PickerStyles.name}>{context.recipe.name}</h2>
                <div className={PickerStyles.guidance}>{context.recipe.guidance}</div>
                <div className={PickerStyles.picker}>
                    <div className={PickerStyles.picker__input}>
                        <label className={PickerStyles.picker__label} htmlFor="doughWeight">
                            <div className={PickerStyles.picker__text}>How much dough?</div>
                            <input className={PickerStyles.picker__weightInput} type="number" id="doughWeight" name="doughWeight" defaultValue={context.doughWeight} onBlur={(e) => context.handleChange(e.target.name,e.target.value)} />
                            <select className={PickerStyles.unitsDropdown} id="units" name="units" defaultValue={context.units} onBlur={(e)=> context.handleChange(e.target.name,e.target.value)}>
                                <option value="g">g</option>
                                <option value="oz">oz</option>
                            </select>
                        </label>
                    </div>
                    <div className={PickerStyles.error__msg}>{error}</div>
                    <div className={PickerStyles.picker__input}>
                        <button aria-label="Next" tabIndex={0} type="button" onClick={handleClick} onKeyDown={handleClick}>Next</button>
                    </div>
                </div>
            </div>
            <div aria-label="Close Modal" role="button" tabIndex={0} className={PickerStyles.backdrop} onClick={clearModal} onKeyDown={handleClick}></div>
        </div>
    )
}
